//	https://medium.com/@mmiller42/advanced-compositional-react-with-usecontext-useref-and-usestate-51702dfedc71

//	& Thank you, Mr. Bond 👍

import React, {
	useRef,
	useState,
	useEffect,
//	useContext
} from 'react';
import classNames from 'classnames';
import { Waypoint } from 'react-waypoint';

//import { window, document } from 'browser-monads';
//const window	=	document.querySelector('main');

const nsBase = 'component';
const ns = `${ nsBase }-reveal`;

const Reveal = ({ children, config, callback, className }) => {

//	const scrollableAncestor	=	useContext(scrollableAncestorContext);
	const scrollableAncestor	=	null;	//	Makes it work ?  🤷
//	console.log('Marker 1',scrollableAncestor);

	const rootClassnames = classNames({
		[`${ ns }`]: true,
		[className]: true
	});

	// settings composed from config props/default props
	const settings = {
		bottomOffset: config.bottomOffset || Reveal.defaultProps.config.bottomOffset,
		initialStyles: config.initialStyles || Reveal.defaultProps.config.initialStyles
	};

	// target
	const targetElement = useRef();

	// reveal state
	const [revealed, setRevealed] = useState(false);

	/**
	*  Responsible for setting revealed state
	*  to true or false
	*/
	const handleReveal = ({ currentPosition }) => {
		if (currentPosition === 'above' || currentPosition === 'inside') {
			setRevealed(true);
		}
	};

	/**
	* Runs callback passed as prop with target element and
	* revealed state for animation manipulation
	*/
	useEffect(() => {
		callback(targetElement.current, revealed);
	}, [revealed, callback]);

	return (
		<Waypoint
			onEnter={handleReveal}
			onLeave={handleReveal}
			bottomOffset={settings.bottomOffset}
			scrollableAncestor={scrollableAncestor}
		>
			<div
				ref={targetElement}
				className={rootClassnames}
				data-revealed={revealed}
				style={settings.initialStyles}
			>
			{children}
			</div>
		</Waypoint>
	);
};

Reveal.defaultProps = {
	callback: (target, revealed) => {
		const elem = target;

		elem.style.opacity = (revealed === true ? 1 : 0);
		elem.style.transform = (revealed === true ? 'translateY(0)' : 'translateY(80px)');
	},
	config: {
		initialStyles: {
			transition: 'all 1.5s cubic-bezier(.165,.84,.44,1)',
			transformStyle: 'preserve-3d',
			opacity: 0,
			transform: 'translateY(80px)'
		},
		bottomOffset: '100px'
	}
};

export default Reveal;